import React, { Fragment } from "react"
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { isMobile } from "../isMobile"

import {
  Typography,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

const styles = theme => ({
  table: {
    marginTop: theme.spacing(2),
    margin: isMobile() ? theme.spacing(-2) : theme.spacing(5),
    padding: isMobile() ? 0 : theme.spacing(2),
  },
  tableCell: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(6),
  },
})

@withStyles(styles)
export default class Table extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <Fragment>
        {this.props.title !== '' && <Typography align='center' variant="h3" component="h3" className={classes.title}>{this.props.title}</Typography>}
        <Paper className={classes.table}>
          <MuiTable>
            <TableHead>
              <TableRow>
                {this.props.head.map((item, idx) => (
                  <TableCell key={idx} className={classes.tableCell}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.content.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    {item.map((cell, idx) => (
                      <TableCell key={idx} className={classes.tableCell}>{cell}</TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        </Paper>
      </Fragment>
    )
  }
}

Table.propTypes = {
  title: PropTypes.string.isRequired,
  head: PropTypes.array.isRequired,
  content: PropTypes.array.isRequired,
}

Table.defaultProps = {
  title: '',
}
