import React from 'react'
import Page from '../../components/page'
import Table from '../../components/table'
import tags from "./tags.png"
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'

import {
  Typography,
} from '@material-ui/core'

const createData = (tag, description, result) => { return [tag, description, result] }

const rowsDate = [
  createData('#ddm#', 'Day in month', '9'),
  createData('#ddmz#', 'Day in month (with leading 0)', '09'),
  createData('#ddy#', 'Day in year', '282'),
  createData('#ddww#', 'Day of week (2 letters)', 'Th'),
  createData('#ddwww#', 'Day of week (3 letters)', 'Thu'),
  createData('#ddwwww#', 'Day of week', 'Thursday'),
  createData('#ddw#', 'Day of week (Sun = 0, Sat = 6)', '5'),
  createData('#dm#', 'Month in year', '1'),
  createData('#dmz#', 'Month in year (with leading 0)', '01'),
  createData('#dmmm#', 'Month in year', 'Jan'),
  createData('#dmmmm#', 'Month in year', 'January'),
  createData('#dyy#', 'Short Year (2 digits)', '14'),
  createData('#dyyyy#', 'Long Year (4 digits)', '2014'),
  createData('#dy#', 'Year', '2016'),
  createData('#dw#', 'Week in year', '40'),
]

const rowsTime = [
  createData('#th#', 'Hour in day (user’s preferred mode)', '9'),
  createData('#th12#', 'Hour in day (1-12)', '9'),
  createData('#th11#', 'Hour in day (0-11)', '9'),
  createData('#th24#', 'Hour in day (1-24)', '21'),
  createData('#th23#', 'Hour in day (0-23)', '21'),
  createData('#thz#', 'Hour in day (preferred) (with leading zero)', '09'),
  createData('#th12z#', 'Hour in day (1-12) (with leading zero)', '09'),
  createData('#th11z#', 'Hour in day (0-11) (with leading zero)', '09'),
  createData('#th24z#', 'Hour in day (1-24) (with leading zero)', '21'),
  createData('#th23z#', 'Hour in day (0-23) (with leading zero)', '21'),
  createData('#tm#', 'Minute in hour', '7'),
  createData('#tmz#', 'Minute in hour (with leading zero)', '07'),
  createData('#ts#', 'Second in minute', '2'),
  createData('#tsz#', 'Second in minute (with leading zero)', '02'),
  createData('#ta#', 'Am/Pm', 'Pm'),
  createData('#tf#', 'Time format (0: 12h, 1: 24h)', '1'),
  createData('#trh#', 'Rotation value for hour (12h)', '97'),
  createData('#trhs#', 'Rotation smooth value for hour (12h)', '180'),
  createData('#trh24#', 'Rotation value for hour hand (24h)', '125'),
  createData('#trh24s#', 'Rotation smooth value for hour hand (24h)', '89'),
  createData('#trm#', 'Rotation value for min hand', '321'),
  createData('#trms#', 'Rotation smooth value for min hand', '124'),
  createData('#trs#', 'Rotation value for second hand', '87'),
  createData('#trss#', 'Rotation smooth value for second hand', '13'),
  createData('#tu#', 'Number of seconds since the Unix Epoch', '1578586060'),
  createData('#tzo#', 'Timezone offset in minutes', '120'),
]

const rowsActivity = [
  createData('#as#', 'Number of steps taken', '1046'),
  createData('#asg#', 'Steps goal', '10000'),
  createData('#ahs#', 'Hourly steps taken (resets every hour)', '245'),
  createData('#am#', 'Number of active zone minutes', '9'),
  createData('#amg#', 'Active zone minutes goal', '200'),
  createData('#ac#', 'Number of calories burned in kCal', '2500'),
  createData('#acg#', 'Calories burned goal in kCal', '10000'),
  createData('#ad#', 'Distance travelled in meters', '09'),
  createData('#adg#', 'Distance goal in meters', '21'),
  createData('#adp#', 'Distance travelled (user’s unit: Km or Mi)', '3.25'),
  createData('#adpu#', 'Distance user’s unit (Km or Mi)', 'Km'),
  createData('#adkm#', 'Distance travelled in kilometers', '3.25'),
  createData('#admi#', 'Distance travelled in miles', '2.82'),
  createData('#ae#', 'Elevation gain, in number of floors climbed', '9'),
  createData('#aeg#', 'Elevation gain goal', '10'),
  createData('#ahr#', 'Heart rate', '60'),
]

const rowsUser = [
  createData('#arhr#', 'Resting heart rate', '56'),
  createData('#uw#', 'User\'s weight in kg', '70'),
]

const rowsSensor = [
  createData('#sb#', 'Battery level in %', '55'),
  createData('#sbc#', 'Battery charging status (1: charging)', ' 0'),
  createData('#stc#', 'Touch counter (+1 everytime the screen is touched)', '875'),
  createData('#sdtc#', 'Double Touch counter (+1 everytime a double tap is detected)', '210'),
]

const rowsWeather = [
  createData('#wu#', 'Temperature Unit (F/C)', 'F'),
  createData('#wt#', 'Temperature (user’s preferred unit)', '68'),
  createData('#wtc#', 'Temperature in Celsius', '20'),
  createData('#wtf#', 'Temperature in Fahrenheit', '68'),
  createData('#wcc#', 'Weather Condition Code (see after)', '0'),
  createData('#wcl#', 'Weather Condition Letter (Upper: day, Lower: night, see after)', 'A'),
  createData('#wd#', 'Day/Night condition (0: night, 1: day)', '0'),
  createData('#wsr#', 'Sunrise in minutes from midnight', '360'),
  createData('#wsrt#', 'Sunrise in text', '8:56'),
  createData('#wss#', 'Sunset in minutes from midnight', '1080'),
  createData('#wsst#', 'Sunset in text (user’s preferred mode)', '21:45'),
  createData('#wmp#', 'Current Moon Phase : 0 to 29 in the North hemisphere, 0 to -29 in the South', '16'),
]

const createData2 = (wcc, wcld, wcln, desc) => { return [desc, wcc, wcld, wcln] }

const rowsWcl = [
  createData2('0', 'A', 'a', 'Clear Sky'),
  createData2('1', 'B', 'b', 'Few Clouds'),
  createData2('2', 'C', 'c', 'Scattered Clouds'),
  createData2('3', 'D', 'd', 'Broken Clouds'),
  createData2('4', 'E', 'e', 'Shower Rain'),
  createData2('5', 'F', 'f', 'Rain'),
  createData2('6', 'G', 'g', 'Thunderstorm'),
  createData2('7', 'H', 'h', 'Snow'),
  createData2('8', 'I', 'i', 'Mist'),
]

const rowsMisc = [
  createData('#mtn#', 'Indicates the current theme number (from 0 to the themes count)', '1'),
]

const TABLES = [
  { title: 'Date', tags: rowsDate },
  { title: 'Time', tags: rowsTime },
  { title: 'Activity', tags: rowsActivity },
  { title: 'User', tags: rowsUser },
  { title: 'Sensor', tags: rowsSensor },
  { title: 'Miscellaneous', tags: rowsMisc },
  { title: 'Weather', tags: rowsWeather },
]

const styles = theme => ({
  title: {
    marginTop: theme.spacing(6)
  },
  img: {
    marginTop: theme.spacing(4),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: '5px',
  },
})

@withRoot
@withStyles(styles)
export default class Tags extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Tags">
        <Typography>
          The properties of each element can be customized in a static way with hardcoded values but to make your clockfaces dynamic, you can use tags in the properties of your elements.<br />
          These placeholders will be replaced at runtime by the real value (hours, minutes, ...).
        </Typography>
        <img src={tags} className={classes.img} style={{ maxWidth: '100%' }} />
        {TABLES.map((e, i) => (
          <Table key={i} title={e.title} head={['Tag', 'Description', 'Result']} content={e.tags} />
        ))}
        <Typography align='center' variant="h4" component="h4" className={classes.title}>
          Weather Condition Codes and Letters
        </Typography>
        <Table head={['Condition', 'wcc', 'wcl for day', 'wcl for night']} content={rowsWcl} />
      </Page>
    )
  }
}
